import React, { useState, useRef, useEffect } from 'react';
import { useUser } from '../contexts/UserContext';
import TrimModal from './TrimModal';
import PreviewModal from './PreviewModal';
import { MdPlayArrow, MdPause, MdDelete, MdContentCut  } from 'react-icons/md';
import { getEndpoint } from '../utils';

const VideoComposer = ({ combinedVideosRef, videoClips = [], setVideoClips, audioClips = [], setAudioClips }) => {
  const [showTrimModal, setShowTrimModal] = useState(false);
  const [currentClipIndex, setCurrentClipIndex] = useState(null);
  const [currentTrack, setCurrentTrack] = useState(null);
  const [draggedAudioIndex, setDraggedAudioIndex] = useState(null);
  const { currentUser, loading, idToken } = useUser();
  const videoRefs = useRef([]);
  const audioRefs = useRef([]);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [playingState, setPlayingState] = useState({ index: null, playing: false }); // State to manage audio play/pause
  const pixelsPerSecond = 100;
  const [totalVideoDuration, setTotalVideoDuration] = useState(0);
  const videoTrackRef = useRef(null);
  const audioTrackRef = useRef(null);

  useEffect(() => {
    audioRefs.current = audioClips.map((_, i) => audioRefs.current[i] || React.createRef());
  }, [audioClips]);

  useEffect(() => {
    videoRefs.current = videoRefs.current.slice(0, videoClips.length);
    recalculateTotalDuration();
  }, [videoClips, audioClips]);

  const recalculateTotalDuration = () => {
    const totalDuration = videoClips.reduce((acc, clip) => acc + ((clip.endTrim - clip.startTrim) / clip.speed), 0);
    setTotalVideoDuration(totalDuration);
  };

  const calculateWidth = (clip) => {
    const effectiveDuration = (clip.endTrim - clip.startTrim) / clip.speed;
    return Math.min(Math.max(50, effectiveDuration * pixelsPerSecond), 600);
  };
  
  const handlePlayPause = (index) => {
    const currentPlaying = playingState.index === index ? !playingState.playing : true;
    setPlayingState({ index, playing: currentPlaying });

    const audioElement = audioRefs.current[index].current;
    if (audioElement) {
      if (currentPlaying) {
        audioElement.play();
      } else {
        audioElement.pause();
      }
    }
  };

  const renderPlayPauseButton = (index) => {
    return playingState.index === index && playingState.playing ? (
      <MdPause className="text-white cursor-pointer" onClick={() => handlePlayPause(index)} />
    ) : (
      <MdPlayArrow className="text-white cursor-pointer" onClick={() => handlePlayPause(index)} />
    );
  };

  const handleDrop = (event, track) => {
    event.preventDefault();
    if (track !== 'video') return; // Ensure we are only handling the video track
  
    const assetUrl = event.dataTransfer.getData('text/asset-url');
    const assetDuration = parseFloat(event.dataTransfer.getData('text/asset-duration'));
    const dragIndex = event.dataTransfer.getData('drag-index');
  
    if (assetUrl && assetDuration) {
      // Handle adding new clip
      const newClip = {
        url: assetUrl,
        isSelected: false,
        startTrim: 0,
        endTrim: assetDuration,
        speed: 1,
        duration: assetDuration,
      };
      addClip(newClip, 'video');
    } else if (dragIndex !== "") {
      // Handle reordering existing clip
      const offsetX = event.nativeEvent.offsetX;
      const totalWidth = event.currentTarget.scrollWidth;
      const totalDuration = videoClips.reduce((acc, clip) => acc + ((clip.endTrim - clip.startTrim) / clip.speed), 0);
      const dropTime = (offsetX / totalWidth) * totalDuration;
  
      let accumulatedTime = 0;
      let dropIndex = 0;
  
      for (let i = 0; i < videoClips.length; i++) {
        const clip = videoClips[i];
        const clipDuration = (clip.endTrim - clip.startTrim) / clip.speed;
        if (accumulatedTime + clipDuration > dropTime) {
          dropIndex = i;
          break;
        }
        accumulatedTime += clipDuration;
      }
  
      const intDragIndex = parseInt(dragIndex);
      if (!isNaN(intDragIndex) && intDragIndex !== dropIndex) {
        rearrangeClip(intDragIndex, dropIndex);
      }
    }
  };

  const moveClipLeft = index => {
    if (index > 0) {
      const newClips = [...videoClips];
      [newClips[index], newClips[index - 1]] = [newClips[index - 1], newClips[index]];
      setVideoClips(newClips);
    }
  };
  
  const moveClipRight = index => {
    if (index < videoClips.length - 1) {
      const newClips = [...videoClips];
      [newClips[index], newClips[index + 1]] = [newClips[index + 1], newClips[index]];
      setVideoClips(newClips);
    }
  };  

  const rearrangeClip = (dragIndex, dropIndex) => {
    const newClips = [...videoClips];
    const [reorderedClip] = newClips.splice(dragIndex, 1);
    newClips.splice(dropIndex, 0, reorderedClip);
    setVideoClips(newClips);
  };
  
  const handleDragStart = (event, index) => {
    event.dataTransfer.setData('drag-index', index.toString());
  };  

  const handleAudioDragStart = (index) => {
    setDraggedAudioIndex(index);
  };

  const handleDropOnAudioTrack = (event) => {
    event.preventDefault();
    const offsetX = event.nativeEvent.offsetX;
    const startPosition = offsetX / pixelsPerSecond;

    // Ensure drop is within total video duration bounds
    if (startPosition >= totalVideoDuration) return; // Early return if drop position exceeds video duration
  
    if (draggedAudioIndex !== null) {
      // Moving an existing clip
      const updatedAudioClips = [...audioClips];
      let clip = updatedAudioClips[draggedAudioIndex];
      const newOffset = Math.min(startPosition, totalVideoDuration - clip.duration);
      const proposedClip = {
        ...clip,
        offset: newOffset
      };
  
      if (!doesOverlap(proposedClip, draggedAudioIndex)) {
        clip.offset = proposedClip.offset;
        setAudioClips(updatedAudioClips);
      }
      setDraggedAudioIndex(null);  // Clear the dragged index after repositioning
    } else {
      // Adding a new clip
      const assetUrl = event.dataTransfer.getData('text/asset-url');
      const assetDuration = parseFloat(event.dataTransfer.getData('text/asset-duration'));
      const actualDuration = Math.min(assetDuration, totalVideoDuration - startPosition);
      const newClip = {
        url: assetUrl,
        isSelected: false,
        startTrim: startPosition,
        endTrim: startPosition + actualDuration,
        speed: 1,
        duration: actualDuration,
        offset: startPosition
      };
  
      if (!doesOverlap(newClip)) {
        setAudioClips(prevAudioClips => [...prevAudioClips, newClip]);
      }
    }
  };  

  const doesOverlap = (newClip, ignoreIndex = null) => {
    // Check if the new clip goes beyond the total video duration
    if (newClip.offset + newClip.duration > totalVideoDuration) {
      return true;
    }
  
    // Check overlap with other clips
    return audioClips.some((clip, index) => {
      if (index === ignoreIndex) return false; // Skip the clip itself if it's being moved
  
      const endNew = newClip.offset + newClip.duration;
      const endCurrent = clip.offset + clip.duration;
      return (newClip.offset < endCurrent && endNew > clip.offset);
    });
  };  

  const addClip = (clip, track) => {
    const clips = track === 'video' ? [...videoClips] : [...audioClips];
    clips.push(clip);
    track === 'video' ? setVideoClips(clips) : setAudioClips(clips);
  };

  const onDeleteClip = (index, track) => {
    const clips = track === 'video' ? [...videoClips] : [...audioClips];
    clips.splice(index, 1);
    track === 'video' ? setVideoClips(clips) : setAudioClips(clips);
  };

  const onTrimClip = (index, track, newStart, newEnd) => {
    if (track === 'audio') {
      const updatedClips = [...audioClips];
      let clip = updatedClips[index];
      clip.startTrim = newStart;
      clip.endTrim = Math.min(newEnd, totalVideoDuration);
      clip.duration = clip.endTrim - clip.startTrim;
      setAudioClips(updatedClips);
    } else {
      setCurrentClipIndex(index);
      setCurrentTrack(track);
      setShowTrimModal(true);
    }
  };

  const shiftClip = (index, direction) => {
    const step = 0.1; // shift by 1 second; adjust as needed
    const newAudioClips = [...audioClips];
    let newOffset = newAudioClips[index].offset + (direction === 'left' ? -step : step);
  
    // Ensure the clip does not move past the start or end of the track
    newOffset = Math.max(0, newOffset); // No less than 0
    newOffset = Math.min(totalVideoDuration - newAudioClips[index].duration, newOffset); // No more than track length minus clip duration
  
    // Check for overlap with other clips if necessary
    if (!doesOverlap({ ...newAudioClips[index], offset: newOffset }, index)) {
      newAudioClips[index].offset = newOffset;
      setAudioClips(newAudioClips);
    }
  };

  const handleCombineClips = async () => {
    const videoClipsList = videoClips.map(clip => [
        clip.url,
        clip.speed, // Assuming the speed isn't dynamically changed, hence setting it to 1
        clip.startTrim, // Calculating actual start time considering speed
        clip.endTrim  // Calculating actual end time considering speed
    ]);
  
    // Sort audio clips by their offset to ensure correct order
    const sortedAudioClips = [...audioClips].sort((a, b) => a.offset - b.offset);

    const audioClipsList = [];
    let lastAudioEnd = 0;
  
    sortedAudioClips.forEach(clip => {
        const startOfCurrentAudio = clip.offset;
        const endOfCurrentAudio = startOfCurrentAudio + clip.duration;
  
        // Handling empty space as 'empty' audio clips
        if (startOfCurrentAudio > lastAudioEnd) {
            audioClipsList.push([
                'empty',
                1,
                0,
                startOfCurrentAudio - lastAudioEnd
            ]);
        }
  
        // Adding actual audio clip
        audioClipsList.push([
            clip.url,
            1,
            0,
            clip.duration
        ]);
  
        lastAudioEnd = endOfCurrentAudio;
    });
  
    // Check if there's a gap after the last audio clip
    if (lastAudioEnd < totalVideoDuration) {
        audioClipsList.push([
            'empty',
            1,
            0,
            totalVideoDuration - lastAudioEnd
        ]);
    }
  
    // Constructing the payload
    const payload = {
        user: currentUser ? currentUser.email : 'Unknown User',  // Checking for currentUser and using email if available
        video_clips_list: videoClipsList,
        audio_clips_list: audioClipsList,
        event_name: 'combine-clips'
    };
  
    try {
        // Send the request to the backend
        const endpoint = `${getEndpoint()}/combine_video_audio_clips_with_speed_trim`;
        const response = await fetch(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${idToken}`,
            },
            body: JSON.stringify(payload),
        });
  
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
  
        // Handle the response from the server
        const data = await response.json();
  
    } catch (error) {
        console.error('Export failed:', error);
        alert('Failed to export clips. Please try again.');
    }
    combinedVideosRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    const handleVideoScroll = () => {
        if (audioTrackRef.current && videoTrackRef.current) {
            audioTrackRef.current.scrollLeft = videoTrackRef.current.scrollLeft;
        }
    };

    const handleAudioScroll = () => {
        if (videoTrackRef.current && audioTrackRef.current) {
            videoTrackRef.current.scrollLeft = audioTrackRef.current.scrollLeft;
        }
    };

    // Get the current elements from the refs
    const videoTrackElement = videoTrackRef.current;
    const audioTrackElement = audioTrackRef.current;

    // Add event listeners
    if (videoTrackElement) {
        videoTrackElement.addEventListener('scroll', handleVideoScroll);
    }
    if (audioTrackElement) {
        audioTrackElement.addEventListener('scroll', handleAudioScroll);
    }

    // Clean up function to remove event listeners
    return () => {
        if (videoTrackElement) {
            videoTrackElement.removeEventListener('scroll', handleVideoScroll);
        }
        if (audioTrackElement) {
            audioTrackElement.removeEventListener('scroll', handleAudioScroll);
        }
    };
  }, []);

  return (
    <div className="composer-area p-4">
      <h2 className="text-xl text-gray-800 font-semibold mb-4">Compose Your Video</h2>
      <div className="overflow-x-auto border-2 border-dashed border-gray-300">
        <h3 className="p-2">Video Track</h3>
        <div 
          ref={videoTrackRef} 
          onDragOver={e => e.preventDefault()} 
          onDrop={e => handleDrop(e, 'video')} 
          className="track overflow-x-auto flex flex-row p-4"
        >
          {videoClips.length > 0 ? (
            videoClips.map((clip, index) => (
              <div key={`video-${index}`} className="clip-item relative flex flex-col" style={{ width: calculateWidth(clip) }} draggable onDragStart={(e) => handleDragStart(e, index)}>
                <div className="border-4 border-yellow-200 rounded" style={{ height: '50px', position: 'relative' }}>
                  <video src={clip.url} ref={el => videoRefs.current[index] = el} className="w-full h-full object-cover" />
                  <div className="absolute inset-0 flex justify-between items-center px-2">
                    <div className="p-1 bg-black rounded-full">
                      <MdContentCut className="text-blue-500 hover:text-blue-700 cursor-pointer" onClick={() => onTrimClip(index, 'video')} />
                    </div>
                    <div className="p-1 bg-black rounded-full">
                      <MdDelete className="text-red-500 hover:text-red-700 cursor-pointer" onClick={() => onDeleteClip(index, 'video')} />
                    </div>
                  </div>
                </div>
                <div className="flex justify-center items-center space-x-2 mt-1">
                  {index > 0 && (
                    <button className="p-1 bg-gray-500 hover:bg-gray-700 text-white rounded text-xs"
                            onClick={() => moveClipLeft(index)}>&larr;</button>
                  )}
                  {index < videoClips.length - 1 && (
                    <button className="p-1 bg-gray-500 hover:bg-gray-700 text-white rounded text-xs"
                            onClick={() => moveClipRight(index)}>&rarr;</button>
                  )}
                </div>
              </div>
            ))
          ) : (
            <div className="text-center text-gray-600 bg-green-200 p-3 rounded-lg w-full">Drag video clips here</div>
          )}
        </div>
        <h3 className="p-2">Audio Track</h3>
        <div className="audio-track-container pt-4 pl-4 pr-4 pb-10" ref={audioTrackRef} onDragOver={e => e.preventDefault()} onDrop={handleDropOnAudioTrack}>
          <div className="audio-track" style={{ width: `${totalVideoDuration * pixelsPerSecond}px`, position: 'relative', backgroundColor: '#ccc', height: '50px' }}>
            {
              audioClips.length > 0 || totalVideoDuration === 0 ? (
                audioClips.map((clip, index) => (
                  <div
                    key={`audio-${index}`}
                    className="relative flex flex-col items-center justify-end"
                    style={{ width: `${clip.duration * pixelsPerSecond}px`, position: 'absolute', left: `${clip.offset * pixelsPerSecond}px`, marginBottom: '20px' }} // Ensure enough space for buttons
                    draggable
                    onDragStart={() => handleAudioDragStart(index)}>
                    <div
                      className="bg-gray-500 bg-opacity-50 border-4 border-yellow-200 w-full flex justify-between items-center"
                      style={{ height: '50px' }}>
                      <div className="bg-black rounded-full p-1 m-1">
                        {renderPlayPauseButton(index)}
                      </div>
                      <div className="bg-black rounded-full p-1 m-1">
                        <MdDelete
                          className="text-red-500 hover:text-red-700 cursor-pointer"
                          onClick={() => onDeleteClip(index, 'audio')} />
                      </div>
                    </div>
                    <div className="flex justify-center items-center space-x-2 mt-2 w-full">
                      <button
                        className="p-1 bg-gray-500 hover:bg-gray-700 text-white rounded text-xs"
                        onClick={() => shiftClip(index, 'left')}>&larr;</button>
                      <button
                        className="p-1 bg-gray-500 hover:bg-gray-700 text-white rounded text-xs"
                        onClick={() => shiftClip(index, 'right')}>&rarr;</button>
                    </div>
                    <audio ref={audioRefs.current[index]} src={clip.url} hidden />
                  </div>
                ))
              ) : (
                <div className="text-left text-gray-600 p-3 rounded-lg w-full overflow-hidden whitespace-nowrap text-overflow-ellipsis">Drag audio clips here</div>
              )
            }

          </div>
        </div>
      </div>
      <button
        className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        onClick={handleCombineClips}
      >
        Combine Clips
      </button>
      {showTrimModal && (
        <TrimModal
          key={currentClipIndex}  // Ensure a unique key for each clip
          clip={currentTrack === 'video' ? videoClips[currentClipIndex] : audioClips[currentClipIndex]}
          onClose={() => setShowTrimModal(false)}
          onSave={(updatedClip) => {
            const clips = currentTrack === 'video' ? [...videoClips] : [...audioClips];
            clips[currentClipIndex] = updatedClip;
            if (currentTrack === 'video') {
              setVideoClips(clips);
            } else {
              setAudioClips(clips);
            }
            setShowTrimModal(false);
          }}
        />
      )}
      {showPreviewModal && (
        <PreviewModal
          videoClips={videoClips}
          audioClips={audioClips}
          onClose={() => setShowPreviewModal(false)}
        />
      )}
    </div>
  );
};

export default VideoComposer;