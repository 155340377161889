import React, { useState, useRef } from 'react';
import Modal from './Modal';

export default function AddSpeechModal({ isOpen, onClose, onSubmit }) {
  const [talkingText, setTalkingText] = useState('');
  const [selectedVoice, setSelectedVoice] = useState('');
  const [isPlaying, setIsPlaying] = useState({});
  const audioRef = useRef(new Audio());

  const MAX_CHARS = 100;

  const voiceOptions = {
    "Male, American, emotive and smooth": "VxHkYP5YFFJw1GwcejMo", // nolan
    "Male, American, newsreader": "5kHAJ3MDKyR1vpj2y28K", // tucker
    "Female, American, introspective": "8IkSW2L63v2qwV3YHCkJ", // Joanne
    "Male, American, deep": "nuD9UsBEJDblGN0Mk6TR", // oldspice1
    "Male, British, confident": "6gMF7JQxW26ggHKbluhJ", // Draco
    "Female, British, serious": "stS1KfcwWQMKEKOlig9B", // hermoine
    "Male, British, old": "Wrwxwe9dsx53qU6jAEjq", // gregory
    "Female, American": "P7x743VjyZEOihNNygQ9", // mod1
    "Male, British, eccentric": "kYGYcASlcCF4ZWGbVy3A", // kYGYcASlcCF4ZWGbVy3A
    "Male, British, child": "1BUhH8aaMvGMUdGAmWVM", // Alyx
    "Dobby ⚡️": "CFYHD8UUfgm1V0F5k7hA", // Dobby
    "Zuck": "HOZndvjvXK43XxoSOQMK", // tech ceo
    "Male, British, young": "MajXM8GGZRjsQyWFawsR", // ron
    "PG": "Pi2Zqk51cRysbs4RoCCF", // pg
    "Male, British, husky": "T2qPBFsHUIQI1Fy8S2Zv", // arthur
    "Male, Russian 2": "WczBIOau2qV9z7nLeDqq", // russia
    "Male, British, young 2": "ZFKXOBSbQ6i7UVkp1k1t", // potter
    "Male, American, young": "g0OCHqTpO1xXO576aj14", // Dylan
    "Male, British, thoughtful": "j9jfwdrw7BRfcR43Qohk", // smooth british voice
    "Female, British, vintage": "He2X3oupwb6Qjs06PN5w", // petunia
    "Churchill": "8UyfbUVyurmhjoeQkw5l", //churchill
    "Male, Transatlantic, radio": "pHBYiezIBFZ2qCiApzmC",
    "Male, American, southern": "D4xQvkd2SmpDZJ8sEwvA", // Jett
    "Female, American, chic": "FiQcvVQvKW27OQwXwjvl", // american woman, chic
    "Trump": "nO08mPUDKcLzF4HwEa8X",
    "Pete": "XCATJYNsB1e9J5NJEZ51",
    "Obama": "knEf3coYMOvaDYDEVYrK",
    "Kim": "wAFeCOSqiz0GGM6iL6Yx",
    "Kanye": "AI0ga0SzYa603qLPyBe7",
  };

  const handleTextChange = (e) => {
    const text = e.target.value;
    if (text.length <= MAX_CHARS) {
      setTalkingText(text);
    }
  };

  const stopAudio = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
    setIsPlaying({});
  };

  const handleVoiceSelect = (value) => {
    setSelectedVoice(value);
    stopAudio(); // Stop any previously playing audio

    const audioUrl = `${process.env.PUBLIC_URL}/${value}.mp3`;
    audioRef.current.src = audioUrl;
    audioRef.current.play().then(() => {
      setIsPlaying({ [value]: true });
      audioRef.current.addEventListener('ended', () => {
        setIsPlaying({ [value]: false });
      });
    }).catch(error => console.error("Audio play failed:", error));
  };

  const handleSubmit = () => {
    onSubmit(talkingText, selectedVoice);
    stopAudio(); // Ensure audio stops when submitting
    onClose();
    setTalkingText('');
    setSelectedVoice('');
  };

  const handleClose = () => {
    stopAudio(); // Ensure audio stops when modal closes
    onClose();
  };
  

  return (
    <Modal isOpen={isOpen} handleClose={handleClose} title="Add Speech" size='small'>
      <div>
        <label htmlFor="talkingText" className="block text-sm font-medium text-gray-700">
          What do you want the character to say?
        </label>
        <textarea
          id="talkingText"
          rows="2"
          placeholder="I'm gonna make him an offer he can't refuse."
          className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
          value={talkingText}
          onChange={(e) => setTalkingText(e.target.value)}
          maxLength={MAX_CHARS}
        />
        <div className="text-sm text-gray-500 mt-1">
          {talkingText.length}/{MAX_CHARS} characters
        </div>
        <div className="mt-4 grid grid-cols-2 gap-2">
          {Object.entries(voiceOptions).map(([label, value]) => (
            <button
              key={value}
              type="button"
              className={`relative py-2 px-4 border rounded-md shadow-sm text-sm font-medium focus:outline-none ${selectedVoice === value ? 'border-green-500 bg-green-500 text-white hover:bg-green-500 hover:border-green-500' : 'border-gray-300 hover:bg-gray-200 hover:border-gray-300'}`}
              onClick={() => handleVoiceSelect(value)}
            >
              {label}
              <span className={`absolute top-1 right-1 text-lg transition-opacity duration-300 ease-in-out ${isPlaying[value] ? 'opacity-100' : 'opacity-0'}`}>
                🔊
              </span>
            </button>
          ))}
        </div>
        <div className='flex flex-row-reverse'>
          <button
            type="button"
            onClick={handleSubmit}
            disabled={!talkingText.trim() || !selectedVoice}
            className={`mt-4 inline-flex justify-center py-2 px-4 text-sm font-medium rounded-md text-white ${!talkingText.trim() || !selectedVoice ? 'bg-gray-300' : 'bg-blue-600 hover:bg-blue-700'} focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`}
          >
            Submit
          </button>
        </div>
      </div>
    </Modal>
  );
}
