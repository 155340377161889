import React from "react";
import { motion } from "framer-motion";

const TimePortalSupport = () => {
  return (
    <div className="relative font-mono flex flex-col items-center justify-center min-h-screen bg-black text-white overflow-hidden p-8">
      {/* Animated Background Gradient */}
      <motion.div
        className="absolute inset-0 z-0"
        animate={{
          background: [
            "radial-gradient(circle at 0% 0%, #ffffff10 0%, #00000000 50%)",
            "radial-gradient(circle at 100% 100%, #ffffff10 0%, #00000000 50%)",
            "radial-gradient(circle at 100% 0%, #ffffff10 0%, #00000000 50%)",
            "radial-gradient(circle at 0% 100%, #ffffff10 0%, #00000000 50%)",
          ],
        }}
        transition={{ duration: 10, repeat: Infinity, repeatType: "reverse" }}
      />

      {/* Animated Border */}
      <motion.div
        className="absolute inset-0 border-8 border-gray-800 z-20 pointer-events-none"
        animate={{
          borderWidth: ["8px", "12px", "8px"],
          borderColor: ["#1a1a1a", "#2a2a2a", "#1a1a1a"],
        }}
        transition={{ duration: 5, repeat: Infinity, ease: "easeInOut" }}
      />

      {/* Content */}
      <div className="z-10 text-center">
        <h1 className="text-4xl md:text-6xl font-bold mb-8">Time Portal Support</h1>
        <motion.div
          className="p-8 rounded-lg bg-white/10 backdrop-blur-md border border-white/20"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <p className="text-xl text-gray-300">
            Having an issue with the Time Portal app?<br />
            Get in touch with us at{" "}
            <a 
              href="mailto:support@eggnog.ai" 
              className="text-purple-400 hover:text-purple-300 transition-colors duration-200"
            >
              support@eggnog.ai
            </a>
          </p>
        </motion.div>
      </div>
    </div>
  );
};

export default TimePortalSupport;