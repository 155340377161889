import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import './styles/EnterTimePortal.css';
import { useUser } from '../contexts/UserContext';
import { auth } from '../Firebase';
import { db } from '../Firebase';
import { doc, getDoc, getDocs, collection } from 'firebase/firestore';
import AppDownloadButton from './AppDownloadButton';
import * as Sentry from '@sentry/react';

const EnterTimePortal = () => {
    const [isAnimating, setIsAnimating] = useState(false);
    const navigate = useNavigate();
    const { currentUser, loading: userLoading } = useUser();
    const username = currentUser?.email || currentUser?.uid;
    const [portalData, setPortalData] = useState(null);
    const [videoUrl, setVideoUrl] = useState(null);
    const [posterUrl, setPosterUrl] = useState(null);
    const [additionalChallenges, setAdditionalChallenges] = useState([]);
    const [allChallenges, setAllChallenges] = useState([]);
    const [currentChallenge, setCurrentChallenge] = useState(null);

    console.log('username', username)

    const trackEvent = async (eventName, eventData) => {
        const data = {
            user: username,
            event_name: eventName,
            manufacturer: 'Web',
            ...eventData,
        };

        fetch('https://timetravel-dd209e3dce1f.herokuapp.com/log', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response) => response.json())
        .then((data) => {
            console.log('Success:', data);
        })
        .catch((error) => {
            Sentry.captureException(error);
            console.error('Error:', error);
        });
    };

    const particlesInit = useCallback(async engine => {
        await loadFull(engine);
    }, []);

    const handleClick = () => {
        setIsAnimating(true);
        window.scrollTo({ top: 0, behavior: 'smooth' });
        
        if (currentChallenge) {
            trackEvent('timeportal_enter', {
                portal_id: currentChallenge.id
            });

            setTimeout(() => {
                navigate(`/timeportal/${currentChallenge.id}`);
            }, 2500);
        }
    };

    useEffect(() => {
        const fetchAllChallenges = async () => {
            try {
                const challengesRef = collection(db, 'timetravel');
                const querySnapshot = await getDocs(challengesRef);
                const now = new Date();
                
                const validChallenges = querySnapshot.docs
                    .map(doc => ({ id: doc.id, ...doc.data() }))
                    .filter(challenge => 
                        challenge.daily_challenge_date && 
                        challenge.daily_challenge_date.toDate() <= now &&
                        challenge.status === "COMPLETE"
                    )
                    .sort((a, b) => 
                        b.daily_challenge_date.toDate() - a.daily_challenge_date.toDate()
                    );

                if (validChallenges.length > 0) {
                    const mostRecent = validChallenges[0];
                    setCurrentChallenge(mostRecent);
                    setPortalData(mostRecent);
                    
                    if (mostRecent.image_urls?.[0]) {
                        const rawUrl = mostRecent.image_urls[0];
                        const cleanUrl = rawUrl.startsWith('@') ? rawUrl.substring(1) : rawUrl;
                        
                        if (cleanUrl.includes('firebasestorage.googleapis.com')) {
                            const urlObj = new URL(cleanUrl);
                            const pathPart = urlObj.pathname.split('/o/')[1];
                            const cleanPath = decodeURIComponent(pathPart);
                            const properlyEncodedPath = encodeURIComponent(cleanPath);
                            setVideoUrl(`${urlObj.origin}/v0/b/${urlObj.pathname.split('/')[3]}/o/${properlyEncodedPath}${urlObj.search}`);
                        } else {
                            setVideoUrl(cleanUrl);
                        }
                    }

                    if (mostRecent.poster_urls?.[0]) {
                        setPosterUrl(mostRecent.poster_urls[0]);
                    }

                    setAdditionalChallenges(validChallenges.slice(1));
                }
            } catch (error) {
                Sentry.captureException(error);
                console.error('Error fetching challenges:', error);
            }
        };

        fetchAllChallenges();
    }, []);

    useEffect(() => {
        console.log('Portal Data:', portalData);
        console.log('Video URL:', videoUrl);
        console.log('Poster URL:', posterUrl);
    }, [portalData, videoUrl, posterUrl]);

    return (
        <div className="min-h-screen bg-black flex flex-col items-center justify-center p-4 overflow-hidden">
            {isAnimating && (
                <Particles
                    id="tsparticles"
                    init={particlesInit}
                    options={{
                        fullScreen: {
                            enable: true,
                            zIndex: 1
                        },
                        particles: {
                            number: {
                                value: 40,
                                density: {
                                    enable: false,
                                }
                            },
                            color: {
                                value: ["#4682B4", "#87CEEB", "#00BFFF", "#1E90FF"],
                            },
                            shape: {
                                type: ["circle", "line"],
                                options: {
                                    line: {
                                        width: 2
                                    }
                                }
                            },
                            opacity: {
                                value: { min: 0.3, max: 0.8 },
                                animation: {
                                    enable: true,
                                    speed: 5,
                                    minimumValue: 0.1,
                                    sync: false
                                }
                            },
                            size: {
                                value: { min: 1, max: 8 },
                                animation: {
                                    enable: true,
                                    speed: 40,
                                    minimumValue: 0.1,
                                    sync: false
                                }
                            },
                            links: {
                                enable: true,
                                distance: 150,
                                color: "#4682B4",
                                opacity: 0.4,
                                width: 2
                            },
                            move: {
                                enable: true,
                                speed: 80,
                                direction: "none",
                                random: true,
                                straight: false,
                                outModes: {
                                    default: "bounce"
                                },
                                attract: {
                                    enable: true,
                                    rotateX: 1200,
                                    rotateY: 1200
                                }
                            },
                            life: {
                                duration: {
                                    sync: false,
                                    value: 2
                                },
                                count: 1
                            },
                            rotate: {
                                value: {
                                    min: 0,
                                    max: 360
                                },
                                direction: "random",
                                animation: {
                                    enable: true,
                                    speed: 60
                                }
                            },
                            tilt: {
                                direction: "random",
                                enable: true,
                                value: {
                                    min: 0,
                                    max: 360
                                },
                                animation: {
                                    enable: true,
                                    speed: 60
                                }
                            },
                        },
                        interactivity: {
                            detect_on: "canvas",
                            events: {
                                onhover: {
                                    enable: true,
                                    mode: ["grab", "repulse"]
                                },
                                resize: true
                            },
                            modes: {
                                grab: {
                                    distance: 400,
                                    links: {
                                        opacity: 1
                                    }
                                },
                                repulse: {
                                    distance: 200
                                }
                            }
                        },
                        background: {
                            color: "#000"
                        },
                        emitters: [
                            {
                                direction: "none",
                                rate: {
                                    quantity: 15,
                                    delay: 0.3
                                },
                                size: {
                                    width: 100,
                                    height: 100
                                },
                                position: {
                                    x: 50,
                                    y: 50
                                }
                            }
                        ]
                    }}
                />
            )}
            
            <h1 className="text-white text-4xl md:text-6xl text-center mb-12 font-bold relative z-10 tracking-wider glowing-text cyberpunk-text">
                TIME PORTAL
            </h1>
            <p className="text-white text-xl md:text-2xl text-center mb-12 relative z-10 tracking-wide glowing-text-subtle">
                Travel through time and figure out which events you landed in
            </p>
            
            <div 
                className="relative w-full max-w-4xl md:aspect-video aspect-square rounded-lg overflow-hidden cursor-pointer"
                onClick={handleClick}
            >
                {videoUrl && (
                    <video
                        src={videoUrl}
                        poster={posterUrl}
                        className="w-full h-full object-cover"
                        autoPlay
                        loop
                        muted
                        playsInline
                    />
                )}
                
                <div className="absolute inset-0 bg-gradient-to-t from-black/70 to-transparent" />
                
                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10">
                    <button
                        onClick={handleClick}
                        className={`px-8 py-4 text-xl bg-transparent text-white rounded-lg transition-all duration-300 
                            border-2 border-[#4682B4] hover:bg-[#4682B4]/20 hover:shadow-[0_0_15px_#4682B4]
                            tracking-wider ${isAnimating ? 'animating lightning-pulse explosion' : ''}`}
                    >
                        PLAY DAILY CHALLENGE
                    </button>
                </div>
            </div>

            <div className="w-full max-w-4xl mt-12 mb-12 text-center">
                <p className="text-white text-xl md:text-2xl mb-6 relative z-10 tracking-wide glowing-text-subtle">
                    Download the app to play more challenges!
                </p>
                <div className="flex justify-center">
                    <AppDownloadButton />
                </div>
            </div>

            <div className="w-full max-w-4xl">
                <h2 className="text-white text-xl md:text-2xl text-center mb-8 relative z-10 tracking-wide glowing-text-subtle">
                    PAST CHALLENGES
                </h2>
                
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    {additionalChallenges.map((challenge) => (
                        <div 
                            key={challenge.id}
                            onClick={() => {
                                window.scrollTo({ top: 0, behavior: 'smooth' });
                                trackEvent('timeportal_enter', {
                                    portal_id: challenge.id
                                });
                                navigate(`/timeportal/${challenge.id}`);
                            }}
                            className="relative aspect-square rounded-lg overflow-hidden cursor-pointer group"
                        >
                            {challenge.image_urls?.[0] && (
                                <video
                                    src={challenge.image_urls[0].startsWith('@') 
                                        ? challenge.image_urls[0].substring(1) 
                                        : challenge.image_urls[0]}
                                    poster={challenge.poster_urls?.[0]}
                                    className="w-full h-full object-cover transition-transform group-hover:scale-105"
                                    autoPlay
                                    loop
                                    muted
                                    playsInline
                                />
                            )}
                            <div className="absolute inset-0 bg-gradient-to-t from-black/70 to-transparent" />
                            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10">
                                <button
                                    className="px-8 py-4 text-xl bg-transparent text-white rounded-lg transition-all duration-300 
                                        border-2 border-[#4682B4] hover:bg-[#4682B4]/20 hover:shadow-[0_0_15px_#4682B4]
                                        tracking-wider uppercase flex flex-col items-center gap-2 whitespace-nowrap"
                                >
                                    <span className="whitespace-nowrap">
                                        {challenge.daily_challenge_date 
                                            ? challenge.daily_challenge_date.toDate().toLocaleDateString('en-US', { 
                                                month: 'long', 
                                                day: 'numeric', 
                                                year: 'numeric' 
                                              })
                                            : 'Time Portal'}
                                    </span>
                                    <span className="text-sm">CHALLENGE</span>
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default EnterTimePortal; 