import { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { db } from '../Firebase';
import { doc, getDoc, collection, getDocs } from 'firebase/firestore';
import { FiShare } from 'react-icons/fi';
import { useUser } from '../contexts/UserContext';
import AppDownloadButton from './AppDownloadButton';
import * as Sentry from '@sentry/react';

const TimePortalResults = () => {
    const { currentUser, loading: userLoading } = useUser();
    const username = currentUser?.email || currentUser?.uid;
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    // Now read gameId, sessionId, and firstRound from the search params
    const gameId = searchParams.get('gameId');
    const sessionId = searchParams.get('sessionId');
    const firstRoundParam = searchParams.get('firstRound') || '';

    const [loading, setLoading] = useState(true);
    const [results, setResults] = useState(null);
    const [error, setError] = useState(null);
    const [copied, setCopied] = useState(false);
    const [showScoreInfo, setShowScoreInfo] = useState(false);
    const [firstRounds, setFirstRounds] = useState([]);
    const [percentiles, setPercentiles] = useState({
        overall: null,
        rounds: Array(5).fill(null),
    });

    const trackEvent = async (eventName, eventData) => {
        const data = {
            user: username,
            event_name: eventName,
            manufacturer: 'Web',
            ...eventData,
        };

        fetch('https://timetravel-dd209e3dce1f.herokuapp.com/log', {
        // fetch('http://127.0.0.1:2999/log', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((data) => {
                console.log('Success:', data);
            })
            .catch((error) => {
                Sentry.captureException(error);
                console.error('Error:', error);
            });
    };

    useEffect(() => {
        if (!userLoading && results) {
            trackEvent('timeportal_results_view', {
                game_id: gameId,
                session_id: sessionId,
                total_score: results.totalScore,
                rounds: results.rounds,
            });
        }
    }, [userLoading, results, gameId, sessionId]);

    useEffect(() => {
        const fetchResults = async () => {
            try {
                if (!gameId) {
                    throw new Error('Missing gameId in query parameters.');
                }

                // Pull the document by gameId
                const docRef = doc(db, 'timetravel-gamesessions', gameId);
                const docSnap = await getDoc(docRef);

                if (!docSnap.exists()) {
                    throw new Error('Game not found');
                }

                const data = docSnap.data();

                // If this is not the first round but we have a known first_round ID, go there
                if (data.round_number !== 1 && data.first_round) {
                    navigate(`/timeportal/${data.first_round}?sessionId=${sessionId}`);
                    return;
                }

                // Ensure all 5 rounds are complete
                const rounds = [
                    data.round_1_score,
                    data.round_2_score,
                    data.round_3_score,
                    data.round_4_score,
                    data.round_5_score,
                ];

                if (rounds.some((score) => score === undefined)) {
                    throw new Error(
                        'Whoops, something went wrong! This is an incomplete game'
                    );
                }

                const totalScore = rounds.reduce((sum, score) => sum + score, 0);

                // Grab the event names if they exist
                const eventNames = [
                    data.portal_1_event_name,
                    data.portal_2_event_name,
                    data.portal_3_event_name,
                    data.portal_4_event_name,
                    data.portal_5_event_name,
                ];

                // Fetch score distributions if we have the results
                if (data) {
                    const portalIds = [];
                    for (let i = 1; i <= 5; i++) {
                        const portalId = data[`portal_${i}_id`];
                        if (portalId) portalIds.push(portalId);
                    }

                    // Fetch and calculate percentiles
                    if (portalIds.length > 0) {
                        const roundPercentiles = await Promise.all(
                            portalIds.map(async (portalId, index) => {
                                const roundStatsRef = doc(
                                    db,
                                    'timetravel-round-stats',
                                    `scoreDistribution-${portalId}`
                                );
                                const roundStatsSnap = await getDoc(roundStatsRef);
                                if (roundStatsSnap.exists()) {
                                    return calculatePercentile(
                                        roundStatsSnap.data().totalScoreBuckets,
                                        rounds[index]
                                    );
                                }
                                return null;
                            })
                        );

                        // Fetch overall game stats
                        const gameStatsRef = doc(
                            db,
                            'timetravel-game-stats',
                            `scoreDistribution-${data.portal_1_id}`
                        );
                        const gameStatsSnap = await getDoc(gameStatsRef);
                        const overallPercentile = gameStatsSnap.exists()
                            ? calculatePercentile(
                                  gameStatsSnap.data().totalScoreBuckets,
                                  totalScore
                              )
                            : null;

                        setPercentiles({
                            overall: overallPercentile,
                            rounds: roundPercentiles,
                        });
                    }
                }

                setResults({
                    rounds,
                    totalScore,
                    created_at: data.created_at,
                    updated_at: data.updated_at,
                    portal_1_id: data.portal_1_id,
                    eventNames,
                });
            } catch (err) {
                Sentry.captureException(err);
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchResults();
    }, [gameId, navigate, sessionId]);

    useEffect(() => {
        const fetchFirstRounds = async () => {
            try {
                const challengesRef = collection(db, 'timetravel');
                const querySnapshot = await getDocs(challengesRef);
                const now = new Date();

                const validChallenges = querySnapshot.docs
                    .map((doc) => ({ id: doc.id, ...doc.data() }))
                    .filter(
                        (challenge) =>
                            challenge.daily_challenge_date &&
                            challenge.daily_challenge_date.toDate() <= now &&
                            challenge.status === 'COMPLETE'
                    )
                    .sort(
                        (a, b) =>
                            b.daily_challenge_date.toDate() -
                            a.daily_challenge_date.toDate()
                    );

                setFirstRounds(validChallenges.map((challenge) => challenge.id));
            } catch (error) {
                Sentry.captureException(error);
                console.error('Error fetching challenges:', error);
            }
        };

        fetchFirstRounds();
    }, []);

    const handleShare = async () => {
        const shareUrl = sessionId
            ? `${window.location.origin}/entertimeportal?sessionId=${sessionId}`
            : `${window.location.origin}/entertimeportal`;

        try {
            await navigator.clipboard.writeText(shareUrl);
            setCopied(true);
            setTimeout(() => setCopied(false), 2000);

            trackEvent('timeportal_results_share', {
                game_id: gameId,
                session_id: sessionId,
                total_score: results?.totalScore,
            });
        } catch (err) {
            Sentry.captureException(err);
            console.error('Failed to copy:', err);
        }
    };

    // A helper function to pick which token to use:
    function getTrophyToken(totalScore) {
        if (totalScore >= 40000) {
            return 'a86359fe-2eeb-4cc3-bbea-ce6780a05ee0'; // gold
        } else if (totalScore >= 30000) {
            return '5872287c-26f3-45d5-b39f-eb20c9c71741'; // silver
        } else {
            return '7ba9968f-5454-4c33-9b1d-d95aca1623b6'; // bronze
        }
    }

    const handleDownload = () => {
        // Make sure `results` is defined
        if (!results) {
            console.error("No 'results' object found, cannot download trophy.");
            return;
        }

        // Decide which trophy token to use
        const token = getTrophyToken(results.totalScore);

        // Track the download event
        trackEvent('timeportal_trophy_download', {
            game_id: gameId,
            session_id: sessionId,
            total_score: results.totalScore,
            trophy_type:
                results.totalScore >= 40000
                    ? 'gold'
                    : results.totalScore >= 30000
                    ? 'silver'
                    : 'bronze',
        });

        // Redirect the user to the proxy route (only pass the short token!)
        const proxyUrl = `https://timetravel-dd209e3dce1f.herokuapp.com/proxy-video/${token}`;
        window.location.href = proxyUrl;
    };

    // -------------------------------
    // Handling next daily challenge
    // -------------------------------
    const currentIndex = firstRounds.indexOf(firstRoundParam);
    const nextFirstRoundParam =
        currentIndex !== -1 && currentIndex < firstRounds.length - 1
            ? firstRounds[currentIndex + 1]
            : firstRounds[0];

    const handleKeepPlaying = () => {
        trackEvent('timeportal_keep_playing', {
            game_id: gameId,
            session_id: sessionId,
            total_score: results?.totalScore,
            next_portal: nextFirstRoundParam,
        });

        navigate(
            `/timeportal/${nextFirstRoundParam}${
                sessionId ? `?sessionId=${sessionId}` : ''
            }`
        );
    };

    const calculatePercentile = (buckets, score) => {
        let totalPlayers = 0;
        let playersBelow = 0;

        // Calculate total players and players below current score
        Object.entries(buckets).forEach(([range, count]) => {
            const [min, max] = range.split('-').map(Number);
            totalPlayers += count;
            if (max < score) {
                playersBelow += count;
            } else if (score >= min && score <= max) {
                // Add half of the players in the current bucket
                playersBelow += count / 2;
            }
        });

        // playersBelow / totalPlayers * 100 = the percentage of players the user beat
        return totalPlayers > 0 ? (playersBelow / totalPlayers) * 100 : null;
    };

    if (loading) {
        return (
            <div className="min-h-screen flex items-center justify-center bg-black">
                <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="min-h-screen flex flex-col items-center justify-center bg-black text-white">
                <div className="text-xl mb-4">{error}</div>
                <button
                    onClick={() => navigate('/')}
                    className="px-4 py-2 bg-blue-500 rounded hover:bg-blue-600"
                >
                    Return Home
                </button>
            </div>
        );
    }

    return (
        <div className="min-h-screen bg-black text-white p-4 sm:p-8">
            <div className="max-w-4xl mx-auto flex flex-col items-center">
                {/* Title */}
                <h1 className="text-3xl sm:text-4xl font-bold text-center mb-6 sm:mb-8 uppercase tracking-wider">
                    Final Results
                </h1>

                {/* Overall Score Section */}
                <div className="flex flex-col items-center mb-6 sm:mb-8 w-full sm:w-auto">
                    <div className="flex items-center justify-center mb-3">
                        <div className="text-[48px] font-bold text-[#22c55e] mr-3">
                            {results.totalScore.toLocaleString()}
                        </div>
                        {/* Question Mark Button */}
                        <button
                            className="ml-2 w-7 h-7 rounded-full bg-[#22c55e] text-black text-sm font-bold flex items-center justify-center"
                            onClick={(e) => {
                                e.stopPropagation();
                                setShowScoreInfo((prev) => !prev);
                            }}
                        >
                            ?
                        </button>
                    </div>
                    <div className="text-sm sm:text-base uppercase tracking-wider text-gray-400">
                        Total Points out of 50,000
                    </div>
                    {/* Overall percentile */}
                    {percentiles.overall !== null && (
                        <div className="text-[#22c55e] mt-2 text-sm sm:text-base">
                            You beat {Math.round(percentiles.overall)}% of players
                        </div>
                    )}
                    {/* Score info popup */}
                    {showScoreInfo && (
                        <div className="relative mt-2">
                            <div className="absolute z-50 bg-gray-800 text-white p-4 rounded-lg shadow-lg w-[280px] sm:w-[320px] text-sm top-0 left-1/2 transform -translate-x-1/2">
                                <button
                                    className="absolute top-2 right-2 text-gray-400 hover:text-white"
                                    onClick={() => setShowScoreInfo(false)}
                                >
                                    ✕
                                </button>
                                <p className="mb-2">How Scoring Works:</p>
                                <p className="mb-2">
                                    Each round can earn up to 10,000 points, for a total
                                    of 50,000. Points are based on how closely you guess
                                    the location and year of each time portal.
                                </p>
                                <p className="mb-2">
                                    • Up to 5,000 points for location accuracy
                                </p>
                                <p>• Up to 5,000 points for year accuracy</p>
                            </div>
                        </div>
                    )}
                </div>

                {/* Trophy Video (if applicable) */}
                {results.totalScore >= 20000 && (
                    <div className="flex flex-col items-center mb-6 sm:mb-8">
                        <video
                            autoPlay
                            muted
                            className="rounded-lg mx-auto"
                            style={{
                                maxWidth: '300px',
                                maxHeight: '200px',
                                backgroundColor: '#000',
                            }}
                        >
                            <source
                                src={
                                    results.totalScore >= 40000
                                        ? 'https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/tmpkbs94f9ptrophy_video_out.mp4?alt=media&token=a86359fe-2eeb-4cc3-bbea-ce6780a05ee0'
                                        : results.totalScore >= 30000
                                        ? 'https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/silver_trophy.mp4?alt=media&token=5872287c-26f3-45d5-b39f-eb20c9c71741'
                                        : 'https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/bronze_trophy.mp4?alt=media&token=7ba9968f-5454-4c33-9b1d-d95aca1623b6'
                                }
                                type="video/mp4"
                            />
                            Your browser does not support the video tag.
                        </video>
                        <div className="mt-4 text-center text-[#22c55e] text-base sm:text-lg">
                            {results.totalScore >= 40000
                                ? "You won a gold trophy! We won't blame you if you want to show it off..."
                                : results.totalScore >= 30000
                                ? "You won a silver trophy! We won't blame you if you want to show it off..."
                                : "You won a bronze trophy! We won't blame you if you want to show it off..."}
                        </div>
                        <button
                            onClick={handleDownload}
                            className="mt-3 inline-block px-4 py-2 bg-transparent text-white rounded-lg transition-all duration-300 border-2 border-[#4682B4] hover:bg-[#4682B4]/20 hover:shadow-[0_0_15px_#4682B4]"
                        >
                            Claim Trophy
                        </button>
                    </div>
                )}

                <div className="text-center mt-8">
                    <p className="text-lg text-gray-300 mb-4">Download the app to play more challenges!</p>
                    <div className="flex justify-center">
                        <AppDownloadButton />
                    </div>
                </div>

                {/* Keep Playing + Share buttons */}
                <div className="flex flex-col sm:flex-row gap-2 mb-8 items-center">
                    <div className="text-center mt-12">
                        <p className="text-lg text-gray-300 mb-6">Or keep playing here</p>
                        <button
                            onClick={handleKeepPlaying}
                            className="px-6 sm:px-8 py-4 bg-transparent text-white rounded-lg transition-all duration-300 border-2 border-[#4682B4] hover:bg-[#4682B4]/20 hover:shadow-[0_0_15px_#4682B4] uppercase tracking-wider relative overflow-hidden text-center text-lg sm:text-xl font-bold"
                            style={{
                                textShadow: '0 0 5px #4682B4, 0 0 10px #4682B4, 0 0 15px #4682B4',
                                boxShadow: '0 0 10px #4682B4',
                            }}
                        >
                            Keep Playing
                        </button>
                    </div>
                </div>

                {/* Score Breakdown */}
                <div className="w-full max-w-[600px]">
                    <h2 className="text-2xl font-bold text-center mb-4 sm:mb-6">
                        Score Breakdown
                    </h2>
                    <div className="flex flex-col gap-3">
                        {results.rounds.map((score, index) => (
                            <div key={index} className="bg-[#222] p-4 rounded-lg w-full">
                                {/* Round # + Score */}
                                <div className="flex justify-between items-center">
                                    <div className="text-white text-base sm:text-lg font-bold">
                                        Round {index + 1}
                                    </div>
                                    <div className="text-[#22c55e] text-base sm:text-lg font-bold">
                                        {score.toLocaleString()} pts
                                    </div>
                                </div>
                                {/* Event Name if present */}
                                {results.eventNames && results.eventNames[index] && (
                                    <div className="text-white mt-2 text-sm sm:text-base break-words">
                                        {results.eventNames[index]}
                                    </div>
                                )}
                                {/* Round percentile */}
                                {percentiles.rounds[index] !== null && (
                                    <div className="text-[#22c55e] mt-2 text-sm sm:text-base">
                                        You beat {Math.round(percentiles.rounds[index])}% of players
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>

                {/* Discord invite */}
                <div className="text-center mt-8">
                    <p className="text-lg text-gray-300 mb-4">
                        Join our Discord for updates
                    </p>
                    <a
                        href="https://discord.gg/NsYP9xFhFe"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="inline-flex items-center bg-[#5865F2] hover:bg-[#4752C4] text-white font-semibold px-4 py-3 rounded-lg transition-colors duration-200"
                    >
                        <img
                            src="https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/discord_white.png?alt=media&token=bc96f6d2-ebd5-44ba-87fc-4db30b2258d9"
                            className="mr-2 h-5 w-6"
                            alt="Discord logo"
                        />
                        Join Discord
                    </a>
                </div>
            </div>
        </div>
    );
};

export default TimePortalResults; 