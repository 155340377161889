import React, { useState, useEffect } from 'react';
import { doc, getDoc, onSnapshot, updateDoc } from 'firebase/firestore';
import { db } from '../Firebase';
import { useParams, useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth'; // ← Import for auth checks

function ReelsCreator() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [jokes, setJokes] = useState([]);                // All jokes fetched from server
  const [selectedJokes, setSelectedJokes] = useState([]); // Which jokes user wants
  const [jokeImages, setJokeImages] = useState({});       // For each joke, store candidate images
  const [chosenImages, setChosenImages] = useState({});   // For each joke, store the chosen onFeedImage & sentToYouImage
  const [finalReels, setFinalReels] = useState([]);       // Final reel results
  const [postStatus, setPostStatus] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [editingJokeIndex, setEditingJokeIndex] = useState(null);
  const [originalJokes, setOriginalJokes] = useState(null); // Add this state to track original values
  const [selectedImage, setSelectedImage] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [combinationSlots, setCombinationSlots] = useState({});  // Will store slots per joke

  const [isAuthorized, setIsAuthorized] = useState(false);
  const [loadingAuth, setLoadingAuth] = useState(true);

  // On mount, check authorization
  useEffect(() => {
    const auth = getAuth();
    const allowedEmails = ['smgplank@gmail.com', 'jitsvm@gmail.com'];
    
    const unsubscribeAuth = auth.onAuthStateChanged((user) => {
      if (user && allowedEmails.includes(user.email)) {
        setIsAuthorized(true);
      } else {
        setIsAuthorized(false);
      }
      setLoadingAuth(false);
    });
    
    return () => unsubscribeAuth();
  }, []);

  /**
   * Step 1: Fetch multiple jokes from the server.
   */
  const handleGenerateJokes = async () => {
    try {
      setIsLoading(true);
      setJokes([]);
      setSelectedJokes([]);
      setFinalReels([]);
      setPostStatus('Fetching multiple AI jokes...');

      const response = await fetch('http://127.0.0.1:2999/generate-ai-jokes', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id: id || null })
      });
      
      if (!response.ok) {
        throw new Error('Failed to fetch AI jokes.');
      }
      const data = await response.json();
      
      // If we don't have an ID in the URL, navigate to the new URL with the ID
      if (!id) {
        navigate(`/reels-creator/${data.doc_id}`);
      }
      
      // Get initial doc and set up listener
      const docRef = doc(db, 'reels-section', id || data.doc_id);
      const docSnap = await getDoc(docRef);
      
      if (docSnap.exists()) {
        setJokes(docSnap.data().jokes || []);
        setPostStatus('Successfully fetched AI jokes!');
        
        // Set up real-time listener for updates
        const unsubscribe = onSnapshot(docRef, (doc) => {
          if (doc.exists()) {
            setJokes(doc.data().jokes || []);
            setPostStatus('Jokes updated!');
          }
        });
        
        // Clean up listener when component unmounts or new jokes are requested
        return () => unsubscribe();
      } else {
        throw new Error('No jokes document found.');
      }
    } catch (err) {
      console.error(err);
      setPostStatus(err.message || 'Error fetching multiple AI jokes.');
    } finally {
      setIsLoading(false);
    }
  };

  // Load existing data if ID is provided
  useEffect(() => {
    if (id) {
      const loadExistingData = async () => {
        try {
          setIsLoading(true);
          const docRef = doc(db, 'reels-section', id);
          const docSnap = await getDoc(docRef);
          
          if (docSnap.exists()) {
            setJokes(docSnap.data().jokes || []);
            setPostStatus('Loaded existing jokes!');
          }
        } catch (err) {
          console.error(err);
          setPostStatus('Error loading existing data');
        } finally {
          setIsLoading(false);
        }
      };
      
      loadExistingData();
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      const docRef = doc(db, 'reels-section', id);
      
      // Set up real-time listener
      const unsubscribe = onSnapshot(docRef, (doc) => {
        if (doc.exists()) {
          // Only update jokes if we're not currently editing
          if (editingJokeIndex === null) {
            setJokes(doc.data().jokes || []);
            setPostStatus('Document updated!');
          }
        }
      });
      
      // Clean up listener when component unmounts
      return () => unsubscribe();
    }
  }, [id, editingJokeIndex]);

  /**
   * Step: Select or deselect jokes from the fetched list.
   */
  const handleSelectJoke = (joke) => {
    if (selectedJokes.includes(joke)) {
      setSelectedJokes(selectedJokes.filter(item => item !== joke));
      // Also clear out any stored images if we unselect
      const newJokeImages = { ...jokeImages };
      delete newJokeImages[joke];
      setJokeImages(newJokeImages);

      const newChosenImages = { ...chosenImages };
      delete newChosenImages[joke];
      setChosenImages(newChosenImages);
    } else {
      setSelectedJokes([...selectedJokes, joke]);
    }
  };

  /**
   * Step 2A: For a given joke, request multiple images for both "onFeed" and "sentToYou".
   * The server should return something like:
   * {
   *    onFeedImages: [ 'url1', 'url2', ... ],
   *    sentToYouImages: [ 'urlA', 'urlB', ... ]
   * }
   */
  const handleGenerateImages = async (joke) => {
    try {
      setIsLoading(true);
      setPostStatus(`Generating images for joke: "${joke}"...`);

      // Find the index of the joke in the jokes array
      const jokeIndex = jokes.indexOf(joke);

      const response = await fetch('http://127.0.0.1:2999/generate-images', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          joke,
          id: id,
          jokeIndex: jokeIndex
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to generate images.');
      }

      const data = await response.json();
      // data: { onFeedImages: string[], sentToYouImages: string[] }

      setJokeImages({
        ...jokeImages,
        [joke]: data
      });

      setPostStatus(`Images generated for: "${joke}". Please review and select.`);
    } catch (err) {
      console.error(err);
      setPostStatus(err.message || 'Error generating images.');
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Step 2B: User picks which image from onFeedImages and which from sentToYouImages they like.
   */
  const handleSelectImage = (joke, isOnFeed, imageUrl) => {
    // Update chosenImages, e.g. chosenImages[joke] = { chosenOnFeed: ..., chosenSentToYou: ... }
    setChosenImages(prev => {
      const existing = prev[joke] || {};
      return {
        ...prev,
        [joke]: {
          ...existing,
          [isOnFeed ? 'chosenOnFeed' : 'chosenSentToYou']: imageUrl
        }
      };
    });
  };

  /**
   * Step 3: Convert chosen images to videos and combine them into a final reel for each selected joke.
   */
  const handleCreateReels = async () => {
    // Make sure every selected joke has chosenOnFeed & chosenSentToYou
    for (let j of selectedJokes) {
      if (
        !chosenImages[j] ||
        !chosenImages[j].chosenOnFeed ||
        !chosenImages[j].chosenSentToYou
      ) {
        alert(`Please select an onFeed image and a sentToYou image for joke: "${j}"`);
        return;
      }
    }

    try {
      setIsLoading(true);
      setFinalReels([]);
      setPostStatus('Creating reels for selected jokes...');

      const reelsArray = [];

      for (let i = 0; i < selectedJokes.length; i++) {
        const joke = selectedJokes[i];
        setPostStatus(`Step (Convert + Combine) for joke ${i + 1} of ${selectedJokes.length}...`);

        // 1. Convert images to videos
        const responseConvert = await fetch('http://127.0.0.1:2999/convert-to-videos', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            onFeedImagePath: chosenImages[joke].chosenOnFeed,
            sentToYouImagePath: chosenImages[joke].chosenSentToYou
          }),
        });
        if (!responseConvert.ok) {
          throw new Error('Failed to convert images to videos.');
        }
        const videoData = await responseConvert.json();
        // e.g. { onFeedVideoPath: '...', sentToYouVideoPath: '...' }

        // 2. Combine into a single vertical reel
        const responseCombine = await fetch('http://127.0.0.1:2999/combine-videos', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(videoData),
        });
        if (!responseCombine.ok) {
          throw new Error('Failed to combine videos.');
        }
        const finalReelData = await responseCombine.json();
        // e.g. { finalVideoUrl: '/path/to/finalReel.mp4' }

        reelsArray.push({
          joke,
          finalVideoUrl: finalReelData.finalVideoUrl,
        });
      }

      setFinalReels(reelsArray);
      setPostStatus('All selected reels have been created successfully!');
    } catch (err) {
      console.error(err);
      setPostStatus(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const formatJoke = (jokeObj) => {
    return `${jokeObj.feed} ${jokeObj.sent}`;
  };

  const handleJokeEdit = (jokeIndex, field, value) => {
    const updatedJokes = jokes.map((j, idx) => {
      if (idx === jokeIndex) {
        return { ...j, [field]: value };
      }
      return j;
    });
    setJokes(updatedJokes);
  };

  const handleEditClick = (idx) => {
    if (editingJokeIndex === idx) {
      // Clicking "Done" - check if changes were made
      const originalJoke = originalJokes[idx];
      const currentJoke = jokes[idx];
      
      if (originalJoke.feed !== currentJoke.feed || originalJoke.sent !== currentJoke.sent) {
        saveJokeChanges();
      }
      setOriginalJokes(null);
      setEditingJokeIndex(null);
    } else {
      // Starting to edit - store original values
      setOriginalJokes([...jokes]);
      setEditingJokeIndex(idx);
    }
  };

  const saveJokeChanges = async () => {
    try {
      const docRef = doc(db, 'reels-section', id);
      await updateDoc(docRef, {
        jokes: jokes
      });
      setPostStatus('Changes saved successfully!');
    } catch (error) {
      console.error('Error saving changes:', error);
      setPostStatus('Error saving changes');
    }
  };

  const ImageModal = ({ imageUrl, onClose }) => {
    if (!imageUrl) return null;
    
    // Check if it's a video URL by looking for video-specific patterns
    const isVideo = imageUrl.includes('/video/') || imageUrl.match(/\.(mp4|webm|ogg)$/i);
    
    return (
      <div 
        className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4"
        onClick={onClose}
      >
        <div className="max-w-4xl max-h-[90vh] relative">
          {isVideo ? (
            <video 
              src={imageUrl} 
              className="max-h-[90vh] object-contain"
              controls
              autoPlay
              onClick={e => e.stopPropagation()}
              style={{ width: 'auto', height: '90vh' }}
            />
          ) : (
            <img 
              src={imageUrl} 
              alt="Full size" 
              className="max-w-full max-h-[90vh] object-contain"
              onClick={e => e.stopPropagation()}
            />
          )}
          <button
            className="absolute top-4 right-4 bg-white rounded-full p-2 text-black hover:bg-gray-200"
            onClick={onClose}
          >
            ✕
          </button>
        </div>
      </div>
    );
  };

  const handleAnimateImage = async (joke, imageUrl, isOnFeed) => {
    try {
      setIsLoading(true);
      setPostStatus(`Animating image for joke: "${joke}"...`);

      // Find the index of the joke in the jokes array since the passed jokeIndex is incorrect
      const actualJokeIndex = jokes.findIndex(j => j === joke);

      const response = await fetch('http://127.0.0.1:2999/generate-video', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          joke,
          id,
          jokeIndex: actualJokeIndex, // Use the correct joke index
          image_url: imageUrl,
          isOnFeed
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to animate image.');
      }

      setPostStatus(`Animation requested for image in joke: "${joke}"`);
    } catch (err) {
      console.error(err);
      setPostStatus(err.message || 'Error animating image.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCombineRequestVideos = async (joke) => {
    try {
      setIsLoading(true);
      setPostStatus(`Combining videos for joke: "${joke}"...`);

      const jokeIndex = jokes.findIndex(j => j === joke);
      const slots = combinationSlots[`joke_${jokeIndex}`];
      if (!slots?.top || !slots?.bottom) {
        throw new Error('Please select both top and bottom videos first');
      }

      const jokeObj = jokes[jokeIndex];

      const response = await fetch('http://127.0.0.1:2999/combine-request-videos', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          id,
          jokeIndex,
          topVideo: slots.top,
          bottomVideo: slots.bottom,
          feedText: jokeObj.feed,
          sentText: jokeObj.sent
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to combine videos.');
      }

      setPostStatus(`Videos combination requested for joke: "${joke}"`);
    } catch (err) {
      console.error(err);
      setPostStatus(err.message || 'Error combining videos.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSlotDrop = (joke, jokeIndex, slot, videoUrl) => {
    console.log('Dropping video:', { joke, jokeIndex, slot, videoUrl });
    setCombinationSlots(prev => {
      const slotKey = `joke_${jokeIndex}`;
      const newState = {
        ...prev,
        [slotKey]: {
          ...prev[slotKey],
          [slot]: videoUrl
        }
      };
      console.log('New combination slots state:', newState);
      return newState;
    });
  };

  if (loadingAuth) {
    return <div className="text-center p-8">Checking authorization...</div>;
  }

  if (!isAuthorized) {
    return (
      <div className="container mx-auto p-4">
        <h1 className="text-2xl font-bold mb-6">Access Denied</h1>
        <p>You do not have permission to view this content.</p>
      </div>
    );
  }

  return (
    <div className="p-4 max-w-xl mx-auto space-y-4">
      <h1 className="text-2xl font-semibold">Instagram Reels Creator (Multi-Image Review)</h1>
      <p className="text-gray-600">
        1) Fetch multiple jokes <br />
        2) Select jokes to use <br />
        3) For each joke, generate multiple images & pick the best ones <br />
        4) Convert & stack into final reels
      </p>

      {/* Step 1: Fetch jokes */}
      <div className="space-y-2">
        <button
          className="px-4 py-2 bg-blue-500 text-white rounded disabled:opacity-50"
          onClick={handleGenerateJokes}
          disabled={isLoading}
        >
          Generate AI Jokes
        </button>
      </div>

      {/* Step 2: Show jokes & let user select */}
      {jokes.length > 0 && (
        <div className="space-y-2">
          <h2 className="font-semibold">Review and Select Jokes</h2>
          {jokes.map((joke, idx) => (
            <div key={idx} className="block border-b py-2">
              <div className="flex items-center mb-2">
                <button
                  className="text-blue-500 text-sm mr-2"
                  onClick={() => handleEditClick(idx)}
                >
                  {editingJokeIndex === idx ? 'Done' : 'Edit'}
                </button>
                <button
                  className="px-2 py-1 bg-purple-500 text-white rounded text-sm"
                  disabled={isLoading}
                  onClick={() => handleGenerateImages(joke)}
                >
                  Generate Images
                </button>
              </div>
              
              {editingJokeIndex === idx ? (
                <div className="space-y-2">
                  <div>
                    <label className="block text-sm text-gray-600">If this is on your feed, </label>
                    <input
                      type="text"
                      className="w-full p-1 border rounded"
                      value={joke.feed || ''}
                      onChange={(e) => handleJokeEdit(idx, 'feed', e.target.value)}
                    />
                  </div>
                  <div>
                    <label className="block text-sm text-gray-600">If this was sent to you, </label>
                    <input
                      type="text"
                      className="w-full p-1 border rounded"
                      value={joke.sent || ''}
                      onChange={(e) => handleJokeEdit(idx, 'sent', e.target.value)}
                    />
                  </div>
                </div>
              ) : (
                <div>
                  <div>If this is on your feed, {joke.feed}</div>
                  <div>If this was sent to you, {joke.sent}</div>
                </div>
              )}

              {/* Images and Videos section */}
              {(joke.feed_image_urls?.length > 0 || joke.sent_image_urls?.length > 0 || 
                joke.feed_video_urls?.length > 0 || joke.sent_video_urls?.length > 0) && (
                <div className="mt-4 space-y-4">
                  {joke.feed_image_urls?.length > 0 && (
                    <div>
                      <p className="font-medium mb-1">Feed Images:</p>
                      <div className="flex flex-wrap gap-2">
                        {[...joke.feed_image_urls].reverse().map((imgUrl, iidx) => (
                          <div
                            key={iidx}
                            className={`border cursor-pointer relative w-[calc(50%-4px)] ${chosenImages[joke]?.chosenOnFeed === imgUrl ? 'border-green-500' : 'border-gray-300'}`}
                          >
                            <img 
                              src={imgUrl} 
                              alt="onFeedCandidate" 
                              className="w-full aspect-square object-cover cursor-pointer hover:opacity-80"
                              onClick={(e) => {
                                e.stopPropagation();
                                setSelectedImage(imgUrl);
                                setShowModal(true);
                              }}
                            />
                            <button
                              className="absolute bottom-2 right-2 bg-blue-500 text-white text-xs px-2 py-1 rounded hover:bg-blue-600"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleAnimateImage(joke, imgUrl, true);
                              }}
                            >
                              Animate
                            </button>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}

                  {/* Feed Videos section */}
                  {joke.feed_video_urls?.length > 0 && (
                    <div>
                      <p className="font-medium mb-1">Feed Videos:</p>
                      <div className="flex flex-wrap gap-2">
                        {[...joke.feed_video_urls].reverse().map((videoUrl, vidx) => (
                          <div
                            key={vidx}
                            className={`border relative w-[calc(50%-4px)] ${chosenImages[joke]?.chosenOnFeed === videoUrl ? 'border-green-500' : 'border-gray-300'}`}
                          >
                            <video 
                              src={videoUrl} 
                              className="w-full aspect-square object-cover cursor-pointer hover:opacity-80"
                              onClick={(e) => {
                                e.stopPropagation();
                                setSelectedImage(videoUrl);
                                setShowModal(true);
                              }}
                              controls
                              draggable="true"
                              onDragStart={(e) => {
                                console.log('Starting drag with URL:', videoUrl);
                                e.dataTransfer.setData('text/plain', videoUrl);
                              }}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  )}

                  {joke.sent_image_urls?.length > 0 && (
                    <div>
                      <p className="font-medium mb-1">Sent Images:</p>
                      <div className="flex flex-wrap gap-2">
                        {[...joke.sent_image_urls].reverse().map((imgUrl, iidx) => (
                          <div
                            key={iidx}
                            className={`border cursor-pointer relative w-[calc(50%-4px)] ${chosenImages[joke]?.chosenSentToYou === imgUrl ? 'border-green-500' : 'border-gray-300'}`}
                          >
                            <img 
                              src={imgUrl} 
                              alt="sentToYouCandidate" 
                              className="w-full aspect-square object-cover cursor-pointer hover:opacity-80"
                              onClick={(e) => {
                                e.stopPropagation();
                                setSelectedImage(imgUrl);
                                setShowModal(true);
                              }}
                            />
                            <button
                              className="absolute bottom-2 right-2 bg-blue-500 text-white text-xs px-2 py-1 rounded hover:bg-blue-600"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleAnimateImage(joke, imgUrl, false);
                              }}
                            >
                              Animate
                            </button>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}

                  {/* Sent Videos section */}
                  {joke.sent_video_urls?.length > 0 && (
                    <div>
                      <p className="font-medium mb-1">Sent Videos:</p>
                      <div className="flex flex-wrap gap-2">
                        {[...joke.sent_video_urls].reverse().map((videoUrl, vidx) => (
                          <div
                            key={vidx}
                            className={`border relative w-[calc(50%-4px)] ${chosenImages[joke]?.chosenSentToYou === videoUrl ? 'border-green-500' : 'border-gray-300'}`}
                          >
                            <video 
                              src={videoUrl} 
                              className="w-full aspect-square object-cover cursor-pointer hover:opacity-80"
                              onClick={(e) => {
                                e.stopPropagation();
                                setSelectedImage(videoUrl);
                                setShowModal(true);
                              }}
                              controls
                              draggable="true"
                              onDragStart={(e) => {
                                console.log('Starting drag with URL:', videoUrl);
                                e.dataTransfer.setData('text/plain', videoUrl);
                              }}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              )}

              {(joke.feed_video_urls?.length > 0 && joke.sent_video_urls?.length > 0) && (
                <div className="mt-4">
                  <p className="font-medium mb-1">Combine Videos:</p>
                  <div className="space-y-2 border rounded p-2">
                    {/* Top slot */}
                    <div 
                      className="border-2 border-dashed p-2 aspect-square flex items-center justify-center"
                      onDragOver={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                      onDrop={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        const url = e.dataTransfer.getData('text/plain');
                        console.log('Dropped URL:', url);
                        if (url.includes('feed_video') || url.includes('.mp4')) {
                          handleSlotDrop(joke, idx, 'top', url);
                        }
                      }}
                    >
                      {combinationSlots[`joke_${idx}`]?.top ? (
                        <div className="relative w-full h-full">
                          <video 
                            key={`${idx}-top-${combinationSlots[`joke_${idx}`].top}`}
                            src={combinationSlots[`joke_${idx}`].top} 
                            className="w-full h-full object-cover" 
                            controls
                          />
                          <button 
                            className="absolute top-0 right-0 bg-red-500 text-white p-1"
                            onClick={() => handleSlotDrop(joke, idx, 'top', null)}
                          >
                            ×
                          </button>
                        </div>
                      ) : (
                        <p className="text-gray-500">Drag feed video here (top)</p>
                      )}
                    </div>
                    
                    {/* Bottom slot */}
                    <div 
                      className="border-2 border-dashed p-2 aspect-square flex items-center justify-center"
                      onDragOver={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                      onDrop={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        const url = e.dataTransfer.getData('text/plain');
                        console.log('Dropped URL:', url);
                        if (url.includes('sent_video') || url.includes('.mp4')) {
                          handleSlotDrop(joke, idx, 'bottom', url);
                        }
                      }}
                    >
                      {combinationSlots[`joke_${idx}`]?.bottom ? (
                        <div className="relative w-full h-full">
                          <video 
                            key={`${idx}-bottom-${combinationSlots[`joke_${idx}`].bottom}`}
                            src={combinationSlots[`joke_${idx}`].bottom} 
                            className="w-full h-full object-cover" 
                            controls
                          />
                          <button 
                            className="absolute top-0 right-0 bg-red-500 text-white p-1"
                            onClick={() => handleSlotDrop(joke, idx, 'bottom', null)}
                          >
                            ×
                          </button>
                        </div>
                      ) : (
                        <p className="text-gray-500">Drag sent video here (bottom)</p>
                      )}
                    </div>
                    
                    <button
                      className="w-full px-4 py-2 bg-blue-500 text-white rounded disabled:opacity-50"
                      disabled={!combinationSlots[`joke_${idx}`]?.top || !combinationSlots[`joke_${idx}`]?.bottom || isLoading}
                      onClick={() => handleCombineRequestVideos(joke)}
                    >
                      Combine Videos
                    </button>
                  </div>
                </div>
              )}

              {/* Separate Combined Videos section */}
              {joke.combined_video_urls?.length > 0 && (
                <div className="mt-4">
                  <p className="font-medium mb-1">Combined Videos:</p>
                  <div className="flex flex-wrap gap-2">
                    {[...joke.combined_video_urls].reverse().map((videoUrl, vidx) => (
                      <div
                        key={vidx}
                        className="border relative w-[calc(50%-4px)]"
                      >
                        <video 
                          src={videoUrl} 
                          className="w-full aspect-[9/16] object-cover cursor-pointer hover:opacity-80"
                          onClick={() => {
                            setSelectedImage(videoUrl);
                            setShowModal(true);
                          }}
                          controls
                        />
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      )}

      {/* Step 3: Create reels from chosen images */}
      {selectedJokes.length > 0 && (
        <div className="space-y-2">
          <button
            className="px-4 py-2 bg-green-500 text-white rounded disabled:opacity-50"
            onClick={handleCreateReels}
            disabled={isLoading}
          >
            Create Reels from Chosen Images
          </button>
        </div>
      )}

      {/* Status / Loading */}
      <div className="text-gray-700">
        <p>{postStatus}</p>
      </div>

      {/* Step 4: Show final reels if created */}
      {finalReels.length > 0 && (
        <div className="space-y-4">
          <h2 className="font-semibold">Final Reels:</h2>
          {finalReels.map((reel, idx) => (
            <div key={idx} className="border rounded p-2">
              <p className="font-semibold mb-2">Joke: {reel.joke}</p>
              <video
                src={reel.finalVideoUrl}
                controls
                width="100%"
                className="border rounded"
              />
            </div>
          ))}
        </div>
      )}

      {showModal && <ImageModal imageUrl={selectedImage} onClose={() => {
        setShowModal(false);
        setSelectedImage(null);
      }} />}
    </div>
  );
}

export default ReelsCreator;